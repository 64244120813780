/* 禁用iPhone中Safari的字号自动调整 */

/* 禁止缩放表单 */

input[type="submit"],
input[type="reset"],
input[type="button"],
input {
  resize: none;
  border: none;
}

/* 取消链接高亮  */

body,
div,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
p,
dl,
dt,
dd,
a,
img,
button,
form,
table,
th,
tr,
td,
tbody,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* 设置HTML5元素为块 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* 图片自适应 */
/* 
img {
  width: 100%;
  height: auto;
  width: auto\9;
  display: block;
  -ms-interpolation-mode: bicubic;
} */

/* 初始化 */

body,
div,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
p,
dl,
dt,
dd,
a,
img,
button,
form,
table,
th,
tr,
td,
tbody,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

em,
i {
  font-style: normal;
}

ul,
li {
  list-style-type: none;
}

strong {
  font-weight: normal;
}

.clearfix:after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: normal;
}

.clearfix {
  zoom: 1;
}
a {
  text-decoration: none;
  color: #969696;
  font-family: "Microsoft YaHei", Tahoma, Arial, sans-serif;
}

a:hover {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-family: "Microsoft YaHei";
}

img {
  border: none;
}

input {
  font-family: "Microsoft YaHei";
}

/*单行溢出*/

.one-txt-cut {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*多行溢出 手机端使用*/

.txt-cut {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-2{
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  overflow:hidden;
}

.text-3{
  display:-webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
  overflow:hidden;
}
.text-4{
  display:-webkit-box;
  -webkit-line-clamp:4;
  -webkit-box-orient:vertical;
  overflow:hidden;
}
.text-5{
  display:-webkit-box;
  -webkit-line-clamp:5;
  -webkit-box-orient:vertical;
  overflow:hidden;
}

/* 移动端点击a链接出现蓝色背景问题解决 */

a:link,
a:active,
a:visited,
a:hover {
  background: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.w50 {
  width: 50%;
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20%;
}

.w33 {
  width: 33.333333%;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.db {
  display: block !important;
}

.dn {
  display: none;
}

.f20 {
  font-size: 0.2rem !important;
}

.f22 {
  font-size: 0.22rem !important;
}

.f24 {
  font-size: 0.24rem !important;
}

.f26 {
  font-size: 0.26rem !important;
}

.f28 {
  font-size: 0.28rem !important;
}

.f32 {
  font-size: 0.32rem !important;
}

.f32 {
  font-size: 0.36rem !important;
}

.b_box {
  box-sizing: border-box;
}

.c_box {
  box-sizing: content-box;
}
.bx{
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.bx2{
  width: 1000px;
  height: 100%;
  margin: 0 auto;
}
.p24 {
  padding: 0 0.24rem !important;
}

.y {
  display: flex;
  /* 使子元素水平居中 */
  /* justify-content: center; */
  /* 使子元素垂直居中 */
  align-items: center;
}

.x {
  display: flex;
  /* 使子元素水平居中 */
  justify-content: center;
  /* 使子元素垂直居中 */
  /* align-items: center; */
}

.xy {
  display: flex;
  /* 使子元素水平居中 */
  justify-content: center;
  /* 使子元素垂直居中 */
  align-items: center;
}

.clearfix:after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.p24 {
  padding: 0 0.24rem;
}

.c99 {
  color: #999999 !important;
}

.c21 {
  color: #212121 !important;
}

.c40 {
  color: #ff5b40 !important;
}

.ccc {
  color: #cccccc !important;
}

.c66 {
  color: #666666 !important;
}

.c89 {
  color: #898989;
}

.c4d {
  color: #4d4d4d;
}

.c64 {
  color: #6470ff;
}

textarea {
  outline: none;
  border: none;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
  -webkit-appearance: none;
  outline: none;
}

input {
  background: none;
  outline: none;
  border: 0px;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #CCCCCC;
  /* font-family: 'Regular'; */
  font-size: 14px;
  font-weight:400;
}

.fw5 {
  font-weight: 500;
}

.fw6 {
  font-weight: 600;
}
.info_h3 {
    color: #47484C;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 24px;
}
/* body {
    scrollbar-arrow-color:#f2f2f3; 
    scrollbar-track-color:#1589ce; 
    scrollbar-face-color:#27aeff;  
    scrollbar-Shadow-color:#1589ce;
}
body::-webkit-scrollbar {width:0px; height:0px;} 
 body::-webkit-scrollbar-track {background-color:#fff;} 
 body::-webkit-scrollbar-thumb {background-color:#dcdcdc; border-radius:0px;} */

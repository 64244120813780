.not-found {
	background-color: #f0f2f5;
	height: 100%;
}

.not-found .left {
	height: 100%;
	background: url('./images/404.png') no-repeat center;
}

.not-found .right {
	padding-left: 50px;
	margin-top: 150px;
}

.not-found .right h1 {
	font-size: 35px;
}
.not-found .right h2 {
	margin-bottom: 20px;
	font-size: 20px;
}

.golden_yn_content {
  width: 100%;
  height: 1080px;
  background-image: url("../img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #ccc;
}
.golden_yn_content .golden_yn_hender {
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.3);
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx {
  height: 100%;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx h1 {
  height: 100%;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx h1 a {
  display: inline-block;
  height: 100%;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx h1 a img {
  margin-top: 20px;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx .yn_hender_nav {
  width: auto;
  height: 100%;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx .yn_hender_nav ul {
  width: auto;
  height: 100%;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx .yn_hender_nav ul li:last-child {
  padding-right: 0;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx .yn_hender_nav ul li:hover a {
  border-bottom: 2px solid #fff;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx .yn_hender_nav ul li {
  padding: 20px;
  box-sizing: border-box;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx .yn_hender_nav ul li a {
  width: 100%;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
}
.golden_yn_content .golden_yn_hender .golden_yn_hender_bx .yn_hender_nav ul .active a {
  border-bottom: 2px solid #fff;
}
.golden_yn_content .golden_yn_lb {
  width: 100%;
  height: 980px;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx {
  width: 86%;
  height: 708px;
  margin-top: 110px;
  position: relative;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_lb_introduce {
  width: auto;
  position: absolute;
  top: 175px;
  left: 10%;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_lb_introduce .words {
  font-size: 60px;
  font-weight: 500;
  color: #FFF;
  line-height: 76px;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_lb_introduce .words2 {
  margin-top: 48px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_lb_introduce .introduce_details_btn {
  display: inline-block;
  width: 178px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  color: #469DFF;
  font-size: 18px;
  margin-top: 44px;
  background-color: #fff;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin {
  position: absolute;
  width: 380px;
  height: 480px;
  top: 114px;
  right: 10%;
  background-color: #fff;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_header {
  width: 100%;
  height: 76px;
  text-align: center;
  color: #469DFF;
  font-size: 22px;
  line-height: 76px;
  font-weight: 600;
  background-color: #f1f1f1;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame {
  width: 100%;
  height: auto;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul {
  width: 100%;
  height: auto;
  padding: 40px 44px 0px 44px;
  box-sizing: border-box;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul li:nth-child(3) {
  border-bottom: none;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul li:nth-child(3) input {
  padding: 0;
  width: 50%;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #DDDDDD;
  margin-top: 20px;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul li:nth-child(3) span {
  display: inline-block;
  width: 40%;
  height: 40px;
  line-height: 40px;
  background-color: #f1f1f1;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul li:nth-child(4) {
  border-bottom: none;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul li:nth-child(4) a {
  width: 100%;
  background-color: #006EBD;
  height: 50px;
  border-radius: 22px;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  color: #fff;
  display: inline-block;
  margin-top: 20px;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul li {
  width: 100%;
  height: 76px;
  border-bottom: 1px solid #DDDDDD;
  box-sizing: border-box;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul li img {
  width: 40px;
  margin-top: 18px;
}
.golden_yn_content .golden_yn_lb .yn_lb_bx .yn_signin .yn_signin_frame ul li input {
  width: 70%;
  height: 100%;
  line-height: 76px;
  font-size: 20px;
  color: #333;
  padding-left: 40px;
}
.golden_yn_advantage {
  width: 100%;
  height: 880px;
  background-color: #fff;
  padding-top: 130px;
  box-sizing: border-box;
}
.golden_yn_advantage .yn_advantage_bx {
  height: 484px;
  background-image: url("../img/ys.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 100px 50px 0 50px;
  box-sizing: border-box;
  position: relative;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_tit span {
  font-size: 46px;
  color: #fff;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_tit em {
  font-size: 35px;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 30px;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_tit p {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_tit .p1 {
  margin-top: 28px;
  margin-bottom: 10px;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list {
  position: absolute;
  width: 100%;
  height: 322px;
  left: 0;
  top: 302px;
  padding: 0 46px;
  box-sizing: border-box;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul {
  width: 100%;
  height: auto;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li:last-child {
  margin-right: 0;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li:hover {
  border-bottom: 6px solid #006EBD;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li:hover .img2 {
  display: inline-block;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li:hover img {
  display: none;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li:hover h2 {
  color: #006EBD;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li {
  cursor: pointer;
  width: 260px;
  height: 322px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-bottom: 6px solid #666;
  box-sizing: border-box;
  margin-right: 20px;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li .img2 {
  display: none;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li img {
  width: 70px;
  margin-top: 34px;
  margin-bottom: 60px;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li h2 {
  font-size: 18px;
  margin-bottom: 28px;
  color: #686868;
}
.golden_yn_advantage .yn_advantage_bx .yn_advantage_list ul li p {
  color: #666666;
  font-size: 16px;
  margin-bottom: 10px;
}
.golden_yn_about_us {
  width: 100%;
  height: 872px;
  background-image: url("../img/gywm.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.golden_yn_about_us .yn_about_us_bx h1 {
  width: 100%;
  height: 162px;
  border-bottom: 2px solid #fff;
  line-height: 162px;
  text-align: center;
  color: #fff;
  font-size: 40px;
  position: relative;
}
.golden_yn_about_us .yn_about_us_bx h1 em {
  position: absolute;
  width: 36px;
  height: 8px;
  display: inline-block;
  margin-left: -18px;
  left: 50%;
  background-color: #fff;
  bottom: -5px;
}
.golden_yn_about_us .yn_about_us_bx .yn_about_us_content {
  width: 100%;
  height: auto;
  padding-top: 80px;
}
.golden_yn_about_us .yn_about_us_bx .yn_about_us_content .about_us_l {
  width: 526px;
}
.golden_yn_about_us .yn_about_us_bx .yn_about_us_content .about_us_l h2 {
  text-align: left;
  font-size: 28px;
  color: #fff;
  margin-bottom: 50px;
}
.golden_yn_about_us .yn_about_us_bx .yn_about_us_content .about_us_l p {
  font-size: 16px;
  color: #fff;
  line-height: 32px;
  margin-bottom: 50px;
}
.golden_yn_about_us .yn_about_us_bx .yn_about_us_content .about_us_l a {
  display: inline-block;
  width: 130px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  color: #fff;
  background-color: #006EBD;
  font-size: 14px;
}
.golden_yn_news {
  width: 100%;
  height: 900px;
  background-color: #F3F6F9;
  padding-top: 60px;
  box-sizing: border-box;
}
.golden_yn_news .yn_news_bx h1 {
  width: 100%;
  height: 162px;
  border-bottom: 2px solid #E4E4E4;
  line-height: 162px;
  text-align: center;
  color: #006EBD;
  font-size: 40px;
  position: relative;
  margin-bottom: 90px;
}
.golden_yn_news .yn_news_bx h1 em {
  position: absolute;
  width: 36px;
  height: 8px;
  display: inline-block;
  margin-left: -18px;
  left: 50%;
  background-color: #006EBD;
  bottom: -5px;
}
.golden_yn_news .yn_news_bx .yn_news_list ul {
  width: 100%;
  height: 370px;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:last-child {
  margin-right: 0;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover {
  background-color: #1150AD;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover > div .news_l {
  border-right: 1px solid #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover > div .news_l h2 {
  color: #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover > div .news_l p {
  color: #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover > div .news_r > div {
  color: #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover > div .news_r p {
  color: #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover > div .news_r a {
  background-color: #fff;
  color: #2961AA;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover > div .news_r a img {
  display: none;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li:hover > div .news_r a .img2 {
  display: inline-block;
}
.golden_yn_news .yn_news_bx .yn_news_list ul .active {
  background-color: #1150AD;
}
.golden_yn_news .yn_news_bx .yn_news_list ul .active > div .news_l {
  border-right: 1px solid #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul .active > div .news_l h2 {
  color: #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul .active > div .news_l p {
  color: #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul .active > div .news_r > div {
  color: #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul .active > div .news_r p {
  color: #fff;
}
.golden_yn_news .yn_news_bx .yn_news_list ul .active > div .news_r a {
  background-color: #fff;
  color: #2961AA;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li {
  width: 380px;
  height: 370px;
  background-color: #fff;
  margin-right: 30px;
  cursor: pointer;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div {
  width: 100%;
  padding: 18px 0px;
  box-sizing: border-box;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div .news_l {
  padding: 0 24px;
  box-sizing: border-box;
  width: 280px;
  height: 120px;
  border-right: 1px solid #E1E1E1;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div .news_l h2 {
  font-size: 16px;
  color: #333333;
  margin-bottom: 22px;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div .news_l p {
  color: #ADADAD;
  font-size: 14px;
  line-height: 24px;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div .news_r {
  padding: 0 24px;
  box-sizing: border-box;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div .news_r > div {
  color: #333333;
  font-size: 18px;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div .news_r > p {
  color: #333333;
  font-size: 16px;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div .news_r a {
  width: 32px;
  height: 32px;
  background-color: #1150AD;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 32px;
  margin-top: 40px;
  font-size: 18px;
}
.golden_yn_news .yn_news_bx .yn_news_list ul li > div .news_r a .img2 {
  display: none;
}
footer {
  width: 100%;
  background-color: #2D2F39;
}
footer .golden_yn_footer_bx {
  padding-top: 47px;
  padding-bottom: 70px;
  box-sizing: border-box;
  border-bottom: 1px solid #f1f1f1;
}
footer .golden_yn_footer_bx .yn_footer_l {
  width: 342px;
  height: 156px;
  border-right: 1px solid #fff;
}
footer .golden_yn_footer_bx .yn_footer_l h2 {
  font-size: 20px ;
  color: #fff;
  margin-top: 10px;
}
footer .golden_yn_footer_bx .yn_footer_l p {
  color: #fff;
  font-size: 16px;
  margin-top: 60px;
  margin-bottom: 10px;
}
footer .golden_yn_footer_bx .yn_footer_l > div {
  color: #006EBD;
  font-size: 20px;
  font-weight: 500;
}
footer .golden_yn_footer_bx .yn_footer_t {
  width: 573px;
  height: 156px;
  padding-left: 56px;
  padding-right: 166px;
  border-right: 1px solid #fff;
  box-sizing: border-box;
  padding-top: 10px;
}
footer .golden_yn_footer_bx .yn_footer_t ul {
  width: 351px;
}
footer .golden_yn_footer_bx .yn_footer_t ul li {
  color: #fff;
  width: 80px;
  font-size: 18px;
  margin-right: 30px;
  margin-bottom: 10px;
}
footer .golden_yn_footer_bx .yn_footer_t p {
  font-size: 16px;
  color: #fff;
  margin-top: 54px;
}
footer .golden_yn_footer_bx .yn_footer_r {
  padding-left: 84px;
  padding-top: 0px;
}
footer .golden_yn_footer_bx .yn_footer_r p {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-top: 8px;
}
footer .golden_yn_bn {
  height: 60px;
  line-height: 60px;
  background-color: white;
  box-sizing: border-box;
}
footer .golden_yn_bn div >a{
  color: rgb(128, 128, 128);
}
footer .golden_yn_bn > p {
  width: 1200px !important;
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  box-sizing: border-box;
}

.fade-enter {
    /*入场*/
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in;
}
.fade-enter-done {
    opacity: 1;
}
.fade-exit {
    /*出场*/
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 1s ease-in;
}
.fade-exit-done {
    opacity: 0;
}
body::-webkit-scrollbar {
    display: none;
}
